'use client';
import * as React from 'react';
import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Button as B, Text, type TextT } from '@mentimeter/ragnar-ui';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import type { JSX } from 'react';
import { Link } from '../ui/actions';
import { Bold, Italic, P } from '../ui/typography';
import { Callout } from '../ui/Callout';
import { AnchorHeading, slugify } from '../components/AnchorHeading';
import type { EmbeddedTableBlock } from './TableRenderer';
import TableRenderer from './TableRenderer';

const getElementChild = (
  children: React.ReactNode,
): React.ReactElement | null | string => {
  const elem = Array.isArray(children) ? children[0] : children;
  if (!elem || elem === true || typeof elem === 'number') return null;
  if (typeof elem === 'string') {
    return elem;
  }
  if ('props' in elem) {
    return elem.props.children;
  }
  return null;
};

export const getTextContent = (children: React.ReactNode): string => {
  const elem = Array.isArray(children) ? children[0] : children;
  if (!elem) return '';

  if (typeof elem === 'string') {
    return elem;
  }
  if (elem === true || typeof elem === 'number') return elem.toString();

  if ('props' in elem) {
    return elem.props.children.toString();
  }

  return '';
};

const Heading = (props: TextT) => (
  <Text
    fontFamily="heading"
    lineHeight="heading"
    color="text"
    fontWeight="semiBold"
    {...props}
  />
);

const H2 = (props: TextT) => <Heading as="h2" fontSize={4} {...props} />;

const H3 = (props: TextT) => <Heading as="h3" fontSize={3} {...props} />;

const H4 = (props: TextT) => <Heading as="h4" {...props} />;

export const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <P
        extend={() => ({
          ':empty': { display: 'none' },
        })}
      >
        {children}
      </P>
    ),
    [BLOCKS.HEADING_2]: (_, children) => {
      return (
        <Box mt={[2, 4]} mb={[1, 3]}>
          <AnchorHeading as={H2} heading={getTextContent(children)} />
        </Box>
      );
    },
    [BLOCKS.HEADING_3]: (_, children) => {
      return (
        <Box mt={[2, 4]} mb={[1, 3]}>
          <AnchorHeading as={H3} heading={getTextContent(children)} />
        </Box>
      );
    },
    //blocks are treated like MARKS these are elements not texts
    [BLOCKS.HEADING_4]: (_, children) => {
      return (
        <Box mt={[2, 4]} mb={[1, 3]}>
          <AnchorHeading as={H4} heading={getTextContent(children)} />
        </Box>
      );
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
    [BLOCKS.OL_LIST]: (_, children) => (
      <Box as="ol" pl={4} mb={[2, 3]} width={1}>
        {children}
      </Box>
    ),
    [BLOCKS.UL_LIST]: (_, children) => (
      <Box as="ul" pl={4} mb={[2, 3]} width={1}>
        {children}
      </Box>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => {
      const elem = getElementChild(children);
      return (
        <Text
          as="li"
          mb={2}
          extend={() => ({
            '&:last-child': {
              marginBottom: 0,
            },
          })}
        >
          {elem}
        </Text>
      );
    },
    [BLOCKS.HR]: () => (
      <Box
        as="hr"
        width="100%"
        borderColor="border"
        borderWidth="1px"
        borderStyle="solid"
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const id = node.data.target?.sys.contentType?.sys.id;
      switch (id) {
        case 'table': {
          const table = node as EmbeddedTableBlock;
          return <TableRenderer table={table} />;
        }
        case 'callout': {
          const { fields } = node.data.target;
          return (
            <Callout
              body={fields?.body ? fields.body : undefined}
              action={
                fields?.action?.fields
                  ? {
                      children: fields.action.fields.text,
                      href: fields.action.fields.href,
                    }
                  : undefined
              }
              theme={
                fields?.mood?.fields
                  ? { colors: fields.mood.fields.colors }
                  : undefined
              }
            />
          );
        }
        default:
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
      }
    },
  },
};

const tableOfContentsOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: () => null,
    [BLOCKS.HEADING_2]: (_, children) => {
      const heading = getTextContent(children);
      const slug = slugify(heading);
      return (
        <B href={`#${slug}`} key={heading} variant="subtle" mb={2}>
          {heading}
        </B>
      );
    },
    [BLOCKS.HEADING_3]: (_, children) => {
      const heading = getTextContent(children);
      const slug = slugify(heading);
      return (
        <B href={`#${slug}`} key={heading} variant="subtle" mb={2} ml={2}>
          {heading}
        </B>
      );
    },
    [BLOCKS.HEADING_4]: () => null,
    [BLOCKS.HEADING_5]: () => null,
    [INLINES.HYPERLINK]: () => null,
    [BLOCKS.UL_LIST]: () => null,
    [BLOCKS.LIST_ITEM]: () => null,
  },
};
interface Props {
  document: Document;
  rendererOptions?: Options;
}

// @disableLinks native apps may not link outside of menti.com
export function DocumentRenderer({
  document,
  rendererOptions = options,
}: Props): React.ReactNode {
  return documentToReactComponents(document, rendererOptions);
}

export function TableOfContentsRenderer({
  document,
  rendererOptions = tableOfContentsOptions,
}: Props): React.ReactNode {
  return documentToReactComponents(document, rendererOptions) as JSX.Element;
}
