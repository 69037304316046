import * as React from 'react';
import { Box, Clickable, Text, PopoverGeneric } from '@mentimeter/ragnar-ui';
import type { LinkT, TooltipGenericT } from '@mentimeter/ragnar-ui';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { usePathname } from '@mentimeter/next-navigation';
import { NavLink } from './NavLink';
import type { MenuAction } from '.';

interface Props {
  action: MenuAction;
  show: boolean;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string | null>>;
  overviewLabel: string | undefined;
  openDropdownLabel?: string;
  placement?: TooltipGenericT['placement'];
}

const MenuLink = ({ children, ...linkProps }: LinkT) => (
  <Box
    as="li"
    mb={3}
    extend={() => ({
      '&:last-child': {
        marginBottom: 0,
      },
    })}
  >
    <NavLink {...linkProps} active={false}>
      {children}
    </NavLink>
  </Box>
);

export const MenuDropdown = ({
  action,
  setSelectedMenu,
  show,
  placement,
  overviewLabel = 'Overview',
  openDropdownLabel = 'Open dropdown',
}: Props) => {
  const pathname = usePathname();

  return (
    <Box
      onMouseEnter={() => setSelectedMenu(action.referenceId || null)}
      onMouseLeave={() => setSelectedMenu(null)}
      alignItems="center"
      py={3}
    >
      <Clickable
        aria-label={openDropdownLabel}
        aria-expanded={show}
        id={action.referenceId}
        onClick={() => {
          setSelectedMenu((prevShow) =>
            prevShow === action.referenceId
              ? null
              : (action.referenceId ?? null),
          );
          action.onClick?.();
        }}
        flexDirection="row"
        alignItems="center"
        extend={({ theme }) => ({
          opacity: 1,
          whiteSpace: 'nowrap',
          '@media (hover: hover)': {
            ':hover': {
              color: theme.colors.primary,
              textDecoration: 'none',
              opacity: 1,
            },
          },
        })}
      >
        <Text fontWeight="semiBold" color="inherit">
          {action.name}
        </Text>
        <Box
          display="inline-flex"
          aria-hidden="true"
          extend={({ theme }) => ({
            verticalAlign: 'middle',
            marginLeft: '2px',
            transition: `transform ${theme.durations[1]}s ease`,
            transform: show ? 'rotate(-180deg)' : 'rotate(0)',
          })}
        >
          <ChevronDownIcon color="currentColor" size={0} />
        </Box>
      </Clickable>
      <PopoverGeneric
        withPortal={false}
        referenceId={action.referenceId || ''}
        show={show}
        placement={placement}
        bg="bg"
        onDismiss={() => setSelectedMenu(null)}
      >
        <Box
          bg="bg"
          as="ul"
          id={`subactions-${action.referenceId}`}
          p={3}
          overflow="hidden"
          borderRadius={1}
          extend={() => ({
            boxShadow: `0 0 2rem rgba(0,0,0,0.2)`,
          })}
        >
          {action.href && (
            <MenuLink
              aria-current={pathname === action.href ? 'page' : undefined}
              href={action.href}
            >
              {overviewLabel}
            </MenuLink>
          )}
          {action?.subActions?.map(({ name, href, ...linkProps }) => (
            <MenuLink
              {...linkProps}
              href={href}
              aria-current={pathname === href ? 'page' : undefined}
              key={name}
              fontSize={2}
            >
              {name}
            </MenuLink>
          ))}
        </Box>
      </PopoverGeneric>
    </Box>
  );
};
