'use client';
import * as React from 'react';
import { Box, Clickable, Text, Collapsable } from '@mentimeter/ragnar-ui';
import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';
import type { NextPage } from 'next';
import { useState } from 'react';
import { NavLink, type NavLinkT } from './NavLink';

interface Props {
  action: Action;
  actions?: Action[] | undefined;
  setShowMenu: (value: boolean) => void;
  overviewLabel?: string | undefined;
  openDropdownLabel?: string | undefined;
}

export type Action = Omit<NavLinkT, 'onClick'> & {
  name: string;
  onClick?: () => void;
};

const ActionCollapsable: NextPage<Props> = ({
  action,
  actions,
  setShowMenu,
  overviewLabel = 'Overview',
  openDropdownLabel = 'Open dropdown',
}: Props) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <Box mb={3} overflow="hidden">
      {actions ? (
        <>
          <Clickable
            aria-label={openDropdownLabel}
            onClick={() => setShow((prevShow) => !prevShow)}
            flexDirection="row"
            alignItems="center"
            extend={() => ({
              '@media (hover: hover)': {
                ':hover': {
                  opacity: 1,
                },
              },
              ':active': {
                opacity: 1,
              },
            })}
          >
            <Text fontSize={4} fontWeight="semiBold" color="text">
              {action.name}
            </Text>
            <Box
              ml={2}
              extend={({ theme }) => ({
                transition: `transform ${theme.durations[1]}s ease`,
                transform: show ? 'rotate(90deg)' : 'rotate(0)',
              })}
            >
              <ChevronRightIcon size={4} />
            </Box>
          </Clickable>
          <Collapsable show={show} aria-expanded={show}>
            {action.href && (
              <NavLink
                href={action.href}
                onClick={() => {
                  setShowMenu(false);
                  action.onClick?.();
                }}
                mb={3}
                ml={4}
                fontSize={4}
                active={false}
                prefetch={action.prefetch ?? null}
                tabIndex={show ? 0 : -1} // If menu is hidden, links should not be focusable
              >
                {overviewLabel}
              </NavLink>
            )}
            {actions.map(({ name, ...linkProps }) => (
              <NavLink
                {...linkProps}
                onClick={() => {
                  setShowMenu(false);
                  action.onClick?.();
                }}
                active={false}
                mb={3}
                ml={4}
                key={name}
                fontSize={4}
                extend={() => ({
                  '&:last-child': {
                    marginBottom: 0,
                  },
                })}
              >
                {name}
              </NavLink>
            ))}
          </Collapsable>
        </>
      ) : (
        <NavLink
          fontSize={4}
          href={action.href}
          active={false}
          onClick={() => {
            setShowMenu(false);
            action.onClick?.();
          }}
        >
          {action.name}
        </NavLink>
      )}
    </Box>
  );
};

export default ActionCollapsable;
